.authform-root {
	max-width: 320px;
	margin: 0 auto;
	position: relative;
	max-height: 840px;
	margin-bottom: 1.5rem;
	min-height: calc(100vh - 50px);
	padding: 1.5rem;
}

.authform-container {
	background-color: var(--white);
	padding: 0 0.5rem;
	padding: 32px 24px 16px 32px;
	border-radius: 8px;
	margin: auto;
}

.login-btn-container {
	margin: 2rem 0 1rem 0;
}

.login-btn {
	width: 100%;
	height: 48px;
	border-radius: 8px;
	font-size: 1rem;
	font-weight: 600;
	background-color: var(--accent);
}

.login-btn:hover {
	background-color: var(--accentHover);
}

.login-btn.loading {
	background-color: var(--accentLight);
	/* rgb(203, 153, 206);*/
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 420px) {
	.authform-root {
		max-width: 360px;
	}
}