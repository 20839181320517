.payout-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 40px;
	padding: .5rem;
	border-radius: 8px;
	background-color: var(--white);
	margin-bottom: .5rem;
	font-size: .9rem;
}

.payout-location {
	width: 40%;
}

.payout-type {
	width: 30%;
}

.payout-actions {
	display: flex;
	flex-direction: column;
}

.payout-verify-btn,
.payout-verified-label {
	border-radius: 8px;
	width: 100%;
	padding: .5rem;
	min-width: 70px;
}

.payout-verify-btn {
	display: flex;
	background-color: var(--accent);
	justify-content: center;
}

.payout-btn-label-hide {
	display: none;
}

.payout-btn-label-show {
	display: flex;
	justify-content: center;
}

.payout-verified-label {
	background-color: darkgray;
	display: flex;
	justify-content: center;
}

@media screen and (min-width: 420px) {
	.payout-item {
		font-size: 1rem;
	}

	.page-header {
		font-size: 1.5rem
	}
}