.navbar-root {
	display: flex;
	align-items: center;
	max-width: 1280px;
	margin: 0 auto;
	box-shadow: rgb(171, 171, 171) 0px 4px 4px -4px;
	justify-content: space-between;
	padding-left: .5rem;
	padding-right: 1rem;
}

.navbar-item {
	align-items: center;
}

.navbar-link:hover {
	border-bottom: 2px solid var(--accent);
}

.logout-btn {
	border-radius: 8px;
	min-width: 100px;
	height: 36px;
	color: var(--white);
	background-color: var(--darkGray);
}

.logout-btn:hover {
	background-color: var(--universal);
}

/** Mobile **/
@media screen and (min-width: 360px) {
	navbar-root {
		flex-direction: row;
	}
}