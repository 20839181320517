.info-nugget-col {
	min-height: 200px;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background: var(--white);
	border-radius: 8px;
	color: var(--text);
	position: relative;
	margin-bottom: 2.5rem;
	cursor: pointer
}

.info-nugget-col:hover {
	transform: scale(1.05);
	box-shadow: rgb(171, 171, 171) 4px 4px 4px -4px;
}

.info-nugget-icon {
	height: 44px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.info-nugget-icon-coral {
	background-color: lightcoral;
}

.info-nugget-icon-coral:hover {
	background-color: #FA8072;
}

.info-nugget-icon-blue {
	background-color: #5D9CEC;
}

.info-nugget-icon-blue:hover {
	background-color: #4A89DC;
}

.info-nugget-icon-gold {
	background-color: var(--accent);
}

.info-nugget-icon-gold:hover {
	background-color: var(--accentHover);
}

.info-nugget-label {
	text-align: center;
	font-weight: 600;
}

.info-nugget-value {
	text-align: center;
}


@media screen and (min-width: 420px) {
	.info-nugget-col:not(:last-child) {
		margin-right: 1rem;
	}
}