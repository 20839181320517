/** Brand Colors **/
:root {
	--primary: #8CD018;
	--primaryLight: #d4f4ff;
	/*#EAF5D7;*/
	--primaryHover: #49ACF4;
	/*#00AEF8;*/
	--secondary: #923B96;
	/*354052*/
	--secondaryLight: rgb(203, 153, 206);
	--secondaryDark: #491D4B;
	--secondaryHover: #77307A;
	--accent: #F7BF23;
	/*#2CB44D #F4983E #8CD018 #FABA29*/
	--accentLight: #FCEBBD;
	--accentHover: #d7a108;
	--secondaryWhite: #EAEBED;
	--text: #354052;
	--universal: #354052;

	/** State Colors **/
	--success: #05CD75;
	--error: #ffa3a3;
	--disabled: #f4f4f7;

	/** Universal Colors **/
	--white: #ffffff;
	--gray: #E6E6E6;
	--lightGray: #F2F2F2;
	--lightMediumGray: #D8D8D8;
	--mediumGray: #979797;
	--mediumGrayHover: #ABABAB;
	--mediumDarkGray: #828282;
	--darkGray: #616161;
	--blackGray: #272822;
	--buttonGray: #F7F7F7;
	--lightBlue: #BBE5FD;


	/** Background Colors **/
	--tagBackground: #5A6577;
	--background: var(--lightGray);
	--editorBackground: var(--blackGray);
}


/** Background-Color Classes **/
.bg-accent {
	background-color: var(--accent);
}

.bg-primary {
	background-color: var(--primary);
}

.bg-secondary {
	background-color: var(--secondary);
}

.bg-universal {
	background-color: var(--universal);
}

.bg-secondary-gradient {
	background: linear-gradient(to right, var(--secondary), var(--secondaryLight))
}

.bg-gray {
	background-color: var(--gray);
}

.bg-bluegray {
	background-color: rgba(208, 211, 220, 0.30);
}

.bg-lightgray {
	background-color: var(--lightGray);
}

.bg-lightmediumgray {
	background-color: var(--lightMediumGray);
}

.bg-lightblue {
	background-color: var(--lightBlue);
}

.bg-white {
	background-color: var(--white);
}


/** Color Classes **/
.color-accent {
	color: var(--accent);
}

.color-accent-dark {
	color: var(--accentHover);
}

.color-primary {
	color: var(--primary);
}

.color-secondary {
	color: var(--secondary);
}

.color-gray {
	color: var(--gray);
}

.color-gray-l {
	color: var(--lightGray);
}

.color-gray-m {
	color: var(--mediumGray);
}

.color-gray-md {
	color: var(--mediumDarkGray);
}

.color-gray-d {
	color: var(--darkGray);
}

.color-gray-b {
	color: var(--blackGray);
}

.color-white {
	color: #FFF;
}

.color-lightblue {
	color: var(--lightBlue);
}

.color-text {
	color: var(--text);
}

.color-lightgray {
	color: #E6E6E6;
}