.section-header,
.form-header,
.page-header {
	font-family: 'Raleway', 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
	text-align: center;
}

@media screen and (min-width: 480px) {
	.section-header {
		font-size: 1.5rem;
	}

	.page-header {
		font-size: 1.5rem
	}
}