.left-nav {
	padding: 2rem .5rem;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
	position: relative;
	min-width: 64px;
	min-height: calc(100vh - 50px);
}

.home-main {
	display: flex;
}


/** Left Nav Styling **/
.icon-button-root {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	align-items: center;
}

.icon-button-root:hover {
	outline: none;
}

.icon-button {
	fill: var(--white) !important;
	font-size: 24px !important;
}

.icon-button:hover,
.icon-button.selected {
	fill: var(--accent) !important;
}


.icon-button-root:not(:last-child) {
	margin-bottom: 40px;
}

.icon-button-label {
	color: var(--white);
	font-size: 0.875rem;
	text-align: center;
	display: none;
}

/** Internals Styling **/
.info-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
}

.main-content {
	width: 100%;
	padding: 1rem;
}

@media screen and (min-width: 420px) {
	.left-nav {
		max-width: 84px;
	}

	.icon-button-label {
		display: flex;
	}

	.icon-button {
		font-size: 32px !important;
	}

	.info-container {
		flex-direction: row;
	}
}