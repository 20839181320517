@import './styles/base.css';
@import './styles/colors.css';
@import './styles/headers.css';
/* @import './styles/layouts.css';
@import './styles/sizes.css'; */


html {
  box-sizing: border-box;
}

html,
body,
figure {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Oxygen', 'Segoe UI', 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:active,
a:focus {
  outline: none !important;
}

button,
div[role=button] {
  border: none;
  cursor: pointer;
}

button:active,
div[role=button]:active {
  outline: none !important;
}

button:active,
div[role=button]:focus {
  outline: none !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p,
figcaption,
label {
  margin: 8px 0;
  padding: 0;
  line-height: 1.5em;
}

label {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--mediumGray);
  font-weight: 300;
  font-size: .875rem;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: var(--mediumGray);
  font-weight: 300;
  font-size: .875rem;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: var(--mediumGray);
  font-weight: 300;
  font-size: .875rem;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--mediumGray);
  font-weight: 300;
  font-size: .875rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number]:focus::-webkit-inner-spin-button,
input[type=number]:focus::-webkit-outer-spin-button,
input[type=number]:hover::-webkit-inner-spin-button,
input[type=number]:hover::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0 2px 0 0;
}

/* Firefox: Hide number picker */
input[type=number] {
  -moz-appearance: textfield;
}

/* // Show number picker on focus */
input[type=number]:hover,
input[type=number]:focus {
  -moz-appearance: number-input;
}

[type=button] {
  -webkit-appearance: none !important;
}

button {
  border: none;
}

sup {
  top: -1.5em;
}

.App {
  text-align: center;
  height: auto;
  display: flex;
  min-height: calc(100vh - 60px);
  flex-direction: column;
}

.success-message p {
  font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
}

.survey-label {
  display: block;
  margin-top: 2rem;
  font-weight: 600;
  font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.bg-accentlight {
  background-color: #f1f7e9;
}

.bg-circles {
  background-image: url("./static/app/bg_circles.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 50%;
}

.bg-secondary {
  background: var(--secondaryDark);
}

.bg-gradient {
  background: linear-gradient(#354052, #045B7E)
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}